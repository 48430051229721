import { gql } from "@apollo/client";

export const CMS_CHECK_TOKEN = gql`
  query CmsCheckToken {
    cmsCheckToken {
      code
      message
      success
    }
  }
`;
