/*global process*/
/*eslint no-undef: "error"*/
import {
  concat,
  HttpLink,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";
import Cookies from "js-cookie";

const httpLink = new HttpLink({ uri: process.env.API_ENDPOINT });

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = Cookies.get("access_token");

  operation.setContext({
    headers: { authorization: token },
  });
  return forward(operation);
});

export const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});
