import HubIcon from "@mui/icons-material/Hub";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import WifiPasswordIcon from "@mui/icons-material/WifiPassword";
import GroupsIcon from "@mui/icons-material/Groups";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import IosShareIcon from "@mui/icons-material/IosShare";

export const Role = {
  ADMIN: "ADMIN",
  SALE: "SALE",
};

export const menu = [
  {
    link: "/",
    title: "Dashboard",
    icon: <AutoGraphOutlinedIcon />,
    children: [],
    role: [Role.ADMIN],
  },
  {
    link: "/",
    title: "Home Mangement",
    icon: <HomeOutlinedIcon />,
    children: [
      {
        link: "/home-manage/content-shortcut",
        title: "Main Section",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
      {
        link: "/home-manage/airport-facility",
        title: "Airport facilities",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
    ],
    role: [Role.ADMIN],
  },
  {
    link: "/",
    title: "Content Mangement",
    icon: <ContentCopyOutlinedIcon />,
    children: [
      {
        link: "/content-manage/content-category",
        title: "Category",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
      {
        link: "/content-manage/content",
        title: "Content",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
    ],
    role: [Role.ADMIN],
  },
  {
    link: "/",
    title: "Feature Mangement",
    icon: <StickyNote2OutlinedIcon />,
    children: [
      {
        link: "/banner-manage",
        title: "Banner",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
      {
        link: "/notification",
        title: "Notification",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
      {
        link: "/core/badge",
        title: "Badge",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
    ],
    role: [Role.ADMIN],
  },
  {
    link: "/",
    title: "Support Mangement",
    icon: <LiveHelpOutlinedIcon />,
    children: [
      {
        link: "/help-center/lost-and-found",
        title: "Lost & Found",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
      {
        link: "/help-center/tourist-help",
        title: "Tourist help",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
      {
        link: "/help-center/user-support",
        title: "User Support",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
    ],
    role: [Role.ADMIN],
  },
  {
    link: "/",
    title: "Privilege",
    icon: <DiamondOutlinedIcon />,
    children: [
      {
        link: "/privilege/campaign",
        title: "Campaign",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
      {
        link: "/privilege/points",
        title: "Points",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
    ],
    role: [Role.ADMIN],
  },
  {
    link: "/",
    title: "User Management",
    icon: <PeopleAltOutlinedIcon />,
    children: [
      {
        link: "/admin",
        title: "Admin",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
      {
        link: "/user",
        title: "USER",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
    ],
    role: [Role.ADMIN],
  },
  {
    link: "/core",
    title: "Core service",
    icon: <HubIcon />,
    children: [
      {
        link: "/core/consent",
        title: "Consent",
        icon: "",
        children: [],
        role: [Role.ADMIN],
      },
    ],
    role: [Role.ADMIN],
  },
  {
    link: "/referral",
    title: "Referral Management",
    icon: <WifiPasswordIcon />,
    children: [],
    role: [Role.ADMIN],
  },
  {
    link: "/user-group",
    title: "User Group",
    icon: <GroupsIcon />,
    children: [],
    role: [Role.ADMIN],
  },
  {
    link: "/landing-page",
    title: "Landing Page",
    icon: <NewspaperIcon />,
    children: [],
    role: [Role.ADMIN],
  },
  {
    link: "/export-report",
    title: "Export Report",
    icon: <IosShareIcon />,
    children: [
      {
        link: "/export-report/campaign",
        title: "Campaign",
        icon: "",
        children: [],
        role: [Role.ADMIN, Role.SALE],
      },
    ],
    role: [Role.ADMIN, Role.SALE],
  },
];
