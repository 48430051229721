import { gql } from "@apollo/client";

export const LOGOUT = gql`
  mutation Mutation {
    cmsLogout {
      code
      message
      success
      payload
    }
  }
`;
