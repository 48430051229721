import Nav from "./components/nav";
import { client } from "./config/apollo";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";

export default function Root(props) {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Nav />
      </BrowserRouter>
    </ApolloProvider>
  );
}
