import React from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import stack from "../assets/icon/main/stack.svg";
import image from "../assets/icon/main/image.svg";
import content from "../assets/icon/main/content.svg";
import newsPaper from "../assets/icon/main/news-paper.svg";

export default function LayoutIndex({ userInfo }) {
  const { cms_firstname } = userInfo;

  const menu = [
    {
      menuName: "Feature\nHighlights",
      link: "/home-manage/content-shortcut",
      icon: stack,
    },
    {
      menuName: "Home\nBanner",
      link: "/banner-manage",
      icon: image,
    },
    {
      menuName: "Content\nCategory",
      link: "/content-manage/content-category",
      icon: content,
    },
    {
      menuName: "Content",
      link: "/content-manage/content",
      icon: newsPaper,
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        height: "100dvh",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography fontSize={36} color={"#343434"}>
        Welcome {cms_firstname}
      </Typography>

      <Box sx={{ mt: 5, width: "40vw" }}>
        <Grid container spacing={3}>
          {menu.map((row, index) => {
            return (
              <Grid item xs={6} key={index}>
                <Link to={row.link} style={{ textDecoration: "none" }}>
                  <Box
                    sx={{
                      background: "#90CAF91F",
                      alignItems: "center",
                      position: "relative",
                      overflow: "hidden",
                      borderRadius: 3,
                      display: "flex",
                      height: "150px",
                      px: 3,
                    }}
                  >
                    <Typography
                      lineHeight={"36px"}
                      color={"#37444F"}
                      fontSize={24}
                      width={"40%"}
                    >
                      {row.menuName}
                    </Typography>

                    <Box sx={{ position: "absolute", right: "-15px" }}>
                      <img src={row.icon} width={112} height={112} alt="" />
                    </Box>
                  </Box>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}
